<template>
  <b-card class="mr-1">
    <b-input-group class="input-group-merge mt-1 mb-1">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input placeholder="Search" />
    </b-input-group>
    <b-table-simple
      striped
      stacked="md"
      noDataText="Você ainda não possui usuários cadastrados."
      class="text-center responsive"
    >
      <b-thead>
        <b-th>Nome</b-th>
        <b-th>Email</b-th>
        <b-th>Data de registro</b-th>
        <b-th>Ação</b-th>
      </b-thead>
      <b-tbody>
        <b-tr :key="i" v-for="(user, i) in users">
          <b-td>{{ user.name }}</b-td>
          <b-td>{{ user.email }}</b-td>
          <b-td>{{ user.created_at | dateFormat }}</b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-warning"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        class="mt-1"
      />
    </b-table-simple>
  </b-card>
</template>
<script>
import Ripple from "vue-ripple-directive";
export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,
    };
  },
  directives: {
    Ripple,
  },
  props: ["users"],
};
</script>